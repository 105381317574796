// Garrett-Pegg-Website.jsx

import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

const GarrettPeggWebsite = () => {
	return (
        <>
            <Helmet titleTemplate="%s | Work | Garrett Pegg" defaultTitle="Garrett Pegg - Web Developer"><title itemProp="name" lang="en">Web Production Specialist</title></Helmet>
            <h1>Web Production Specialist</h1>
            <h2>Money Map Press</h2>
            <hr />
            <h3>General Info</h3>
            <p>I hated this job.</p>
            <p>This job was miserable. This place made me feel like a cog and the leaders didn't care.</p>
            <p>Now that that is out of the way, I didn't learn any real techincal skills at this job. I guess I learned about ticketing systems like Jira. I did learn life skills though. I learned to communicate and work with teammates. At my pervious job as a <a href="/Work/Web-Dev-Intern">web development intern</a> we had a team, but we all worked on separate projects, so I'm not fooling you when I say I did learn to work on a team.</p>

            <hr class="divider" />
            
            <h3>Duties</h3>
            <ul>
                <li>Daily production of HTML emails, using Photoshop and Dreamweaver with small team using ticketing system, Jira</li>
                <li>Make recommendations to optimize and streamline our HTML email templates</li>
                <li>Create and update web pages, via WordPress, using HTML and CSS best practices</li>
                <li>Manage online content and make recommendations of how to improve production processes or optimize code</li>
            </ul>
        </>
	)
}

export default GarrettPeggWebsite