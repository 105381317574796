// Garrett-Pegg-Website.jsx

import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

const GarrettPeggWebsite = () => {
	return (
        <>
            <Helmet titleTemplate="%s | Projects | Garrett Pegg" defaultTitle="Garrett Pegg - Web Developer"><title itemProp="name" lang="en">Mylife Website</title></Helmet>
            <h1>Garrett Pegg</h1>
            <h2>Mylife Website</h2>
            <hr />
            <h3>General Info</h3>
            <p>This project was a mess. My office was redesiging a website for another office. That office requested the redesign and owned the content... But getting the new content from them was like pulling teeth.</p>

            <p>Okay, enough griping. I was not involved at the earliest stages, but I joined before any movement occured. I interviewed potential users; I designed the website; I built the templates; I did almost everything for this website. I count my lucky stars I was not the one who had to pull teeth to get the content.</p>

            <p>We went through so many designs for two reasons: 1. I was not a designer. 2. The clients didn't even know what they wanted. We interviewed potential users and they wanted neon colors or footballs on the website (I am dead serious about that).I honestly don't know how we even finalized a design. We obviously disregarded footballs, but we loosely interpreted other requests for website designs. Neon colors? We added a couple of bright colors that could be considered neon. Users hate clicking through things (apprently), so we tried to put the majority of information on the front page of the website; We tried to make it succint.</p>

            <p>My boss wanted features that could be flaunted, so I offered customization of the website. Customization? What does that entail? Well, it started with a dark mode (which is now everywhere), then expanded to chosing a secondary color, and then even choosing what logo you want to see throughout the website. I'm actually pretty proud of the customization because that was all me, baby. I had the idea, I coded it to life, I got no credit. That's not a typo. I got nothing for something I took so much pride in, but that's how the world goes.</p>

            <p>Eventually, ideas for content started to come alive. The clients wanted a resource hub. That's what the website became: a host of a bunch of resources. Well, "host" isn't the right word because the website sends users to other websites, it doesn't actually host many resources. They wanted to have any events listed for those who were interested. They wanted announcements to tell users about, say, college applications.</p>

            <p>Despite the pain, I was (still am) proud of my hard work. My first big-time website created from scratch (techinally a redesign, but we basically started from scratch). Unfortunately, the website now spends most of it's time collecting dust.</p>

            <a href="https://mylife.maryland.gov" class="demo_link" target="_blank" rel="nofollow">Mylife Website</a>

            <hr class="divider" />

            <h3>Skills</h3>
            <ul>
                <li>HTML</li>
                <li>CSS</li>
                <li>JavaScript</li>
                <li>jQuery</li>
                <li>Cookies</li>
                <li>Website Design</li>
                <li>Working in a Team</li>
                <li>Communication</li>
                <li>Template Design/Coding</li>
            </ul>
        </>
	)
}

export default GarrettPeggWebsite