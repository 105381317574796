// Home.jsx

import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

const Home = () => {
	return (
        <>
            <Helmet titleTemplate="%s | Web Developer" defaultTitle="Garrett Pegg - Web Developer"><title itemProp="name" lang="en">Garrett Pegg</title></Helmet>
                <h1>Garrett Pegg</h1>
                <h2>Web Developer</h2>
                <hr />
                <p>My name is Garrett Pegg and this is my website. I guess I'll start with a little about myself. I'm just a simple man, like that Lynyrd Skynyrd song. I like to eat food and I exercise to try not to gain too much weight and just because I like to exercise. Don't worry, I'm not one of those people that incorporate the gym into their personality. I love video games, read manga (Japanese comics - no, I don't know Japanese),  listen to heavy metal, and some other stuff I can't think of right now.</p>

                <p>How did I get here? Well, I was an A-B student in high school and had no idea what I wanted to do for a career. I decided to go to Stevenson University (look at the track jersey in the photo) because my friends decided to go there and it was D3, so I could continue running Track. Also, it wasn't too far from home. I was undecided until I had to decide halfway through my sophomore year. I decided on Information Systems because I like video games and thought it may lead to programming video games. Spoiler alert, I did not end up programming video games. I am a web developer... as the second header on this website says... Kinda spoiled that, didn't I?</p>

                <p>After graduating in 2016 I went on to work an internship for the year of 2017 at the Chesapeake Bay Program. I was tasked with working on the <a href="https://www.baybackpack.com/" target="_blank" rel="nofollow">baybackpack website</a>. The place was beautiful, the people were wonderful, and the cause was great. It was an experience I will never forget, and it currently is still a big influence on my life today. In December of 2017, I took an "HTML Specialist" job with a company I refuse to promote. That job told me a lot about what I DON'T want to do with my life. </p>

                <p>I took almost exactly a year off after the 2018 job. I polished my skills, applied for jobs, and interviewed throughout that year. Eventually, the Maryland Department of Human Services called me and asked if I would be interested in the job. I started the job March of 2019. Now, here I am 5 years and 3 months later, typing a truncated bio for my website. It's been a great five years - not updating my website, but I finally thought it was time. I've spent about two months trying to finalize a desing for my website. Taking one inspiration from a video game, one inspiration from a manga cover, and this final inspiration from just wanting to get away from it all. I think I finally nailed it with this design. It's simple, cute, and concise.</p>
        </>
	)
}


export default Home