// Work.jsx

import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

const Contact = () => {
	return (
        <>
            <Helmet titleTemplate="%s | Garrett Pegg" defaultTitle="Garrett Pegg - Web Developer"><title itemProp="name" lang="en">Contact Information</title></Helmet>
            <h1>Garrett Pegg</h1>
            <h2>Contact Information</h2>
            <hr />
            <p>Don't contact me.</p>
            <p>Unless you are offering me a three-figure salary, do not contact me.</p>
            <p>Cold calls aren't cool. I don't want to be an "HTML Specialist" because I know that's a fancy way of saying "Professional Copy &amp; Paster." I do not speak with recruiters or recruting companies. These three things are specifically listed due to horrible personal experiences.</p>
            <p>I have a < a href="https://www.linkedin.com/in/garrett-a-pegg" target="_blank">LinkedIn profile</a>. You can go check out and send me a message (unless you're a recruiter).</p>
        </>
	)
}

export default Contact