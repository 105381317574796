import {
  NavLink,
  Route,
  Routes,
  BrowserRouter as Router,
} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './App.css';
import Home from './Components/Home';
import Work from './Components/Work';
import Contact from './Components/Contact';
import Projects from './Components/Projects';
import GarrettPeggWebsite from './Components/Projects/GarrettPeggWebsite';
import BayBackpackWebsite from './Components/Projects/BayBackpackWebsite';
import JqueryTicTacToe from './Components/Projects/JqueryTicTacToe';
import MylifeWebsite from './Components/Projects/MylifeWebsite';
import WebDevIntern from './Components/Work/WebDevIntern';
import WebmasterII from './Components/Work/WebmasterII';
import WebmasterTrainee from './Components/Work/WebmasterTrainee';
import WebProdSpecialist from './Components/Work/WebProdSpecialist';

function App() {
  return (
    <div className="App">
      <Helmet titleTemplate="%s | Garrett Pegg" defaultTitle="Garrett Pegg - Web Developer">
        <title itemProp="name" lang="en">Garrett Pegg - Web Developer</title>
        <meta name="description" content="My name is Garrett Pegg and I am a web developer. Welcome to my website. Pretty cool, right?" />
        <meta name="keywords" content="reactjs, garrett pegg, coding, web development" />
        <meta name="author" content="Garrett Pegg" />
        <meta property="og:title" content="Garrett Pegg - Web Developer" />
        <meta property="og:description" content="My name is Garrett Pegg and I am a web developer. Welcome to my website. Pretty cool, right?" />
        <meta property="og:image" content="/GarrettPegg.jpg" />
        <meta property="og:url" content="https://garrettpegg.com/" />
        <meta name="twitter:title" content="Garrett Pegg - Web Developer" />
        <meta name="twitter:description" content="My name is Garrett Pegg and I am a web developer. Welcome to my website. Pretty cool, right?" />
        <meta name="twitter:image" content="/GarrettPegg.jpg" />
        <meta name="twitter:card" content="My name is Garrett Pegg and I am a web developer. Welcome to my website. Pretty cool, right?" />
      </Helmet>
        <Router>
          <div class="navigation">
            <ul class="nav-ul">
              <li><NavLink to={'/'}>Home</NavLink></li>
              <li><NavLink to={'/Work'}>Work</NavLink></li>
              <li><NavLink to={'/Projects'}>Projects</NavLink></li>
              <li><NavLink to={'/Contact'}>Contact</NavLink></li>
            </ul>
          </div>

          <div class="body-wrapper">
            <div class="body">
              <div class="content">
              <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/Work" element={<Work />} />
                  <Route path="/Projects" element={<Projects />} />
                  <Route path="/Contact" element={<Contact />} />
                  <Route path="/Projects/Garrett-Pegg-Website" element={<GarrettPeggWebsite />} />
                  <Route path="/Projects/Bay-Backpack-Website" element={<BayBackpackWebsite />} />
                  <Route path="/Projects/Jquery-Tic-Tac-Toe" element={<JqueryTicTacToe />} />
                  <Route path="/Projects/Mylife-Website" element={<MylifeWebsite />} />
                  <Route path="/Work/Web-Dev-Intern" element={<WebDevIntern />} />
                  <Route path="/Work/Webmaster-II" element={<WebmasterII />} />
                  <Route path="/Work/Webmaster-Trainee" element={<WebmasterTrainee />} />
                  <Route path="/Work/Web-Production-Specialist" element={<WebProdSpecialist />} />
              </Routes>
            </div>
          </div>
        </div>
        </Router>
        {/* Clouds  */}
        <div class="cloud cloud-0"></div>
        <div class="cloud cloud-1"></div>
        <div class="cloud cloud-2"></div>
        <div class="cloud cloud-3"></div>
        <div class="cloud cloud-4"></div>
        <div class="cloud cloud-5"></div>
        {/* The road  */}
        <div class="road road-top"></div>
        <div class="road road-bottom"></div>
        {/* The car  */}
        <div class="car">
          {/* Car body  */}
          <div class="car-body car-move"></div>
          <div class="windshield car-move"></div>
          <div class="car-nose car-move"></div>
          {/* Wheels  */}
          <div class="wheel wheel-1 wheel-move"></div>
          <div class="wheel wheel-2 wheel-move"></div>
        </div>
      </div>
  );
}

export default App;
