// Garrett-Pegg-Website.jsx

import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

const GarrettPeggWebsite = () => {
	return (
        <>
            <Helmet titleTemplate="%s | Work | Garrett Pegg" defaultTitle="Garrett Pegg - Web Developer"><title itemProp="name" lang="en">Web Development Intern</title></Helmet>
            <h1>Web Development Intern</h1>
            <h2>University of Maryland Center for Environmental Science <br />(Chesapeake Bay Program)</h2>
            <hr />
            <h3>General Info</h3>
            <p>A very dear experience to me. The people that worked here cared about each other and worked hard for a good cause. The duties below encompass the entirety of the job since I wrote them down and had them reviewed by my boss before I left this position. There's not really much else to say. I loved everything about this job. I lived, I learned, I remember fondly.</p>

            <hr class="divider" />

            <h3>Duties</h3>
            <ul>
                <li>Led the development of new sections (MWEE and Schoolyard Projects) of the BayBackpack.com website using HTML, CSS, Bootstrap, JavaScript, ExpressionEngine, MySQL, and PHP, using mobile-first and responsive design principles</li>
                <li>Created prototypes and mockups, using Balsamic, for new sections of the BayBackPack.com website</li>
                <li>Updated and maintained the content management system (CMS) to support the BayBackpack.com website</li>
                <li>Analyzed website analytics and presented the data to the Chesapeake Bay Program Creative Team</li>
                <li>Actively participated in user testing to improve various Chesapeake Bay Program websites</li>
                <li>Actively participated in bi-weekly team trainings to learn of the importance of User Experience (UX) and methods to determine user needs</li>
            </ul>
        </>
	)
}

export default GarrettPeggWebsite