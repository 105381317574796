// Garrett-Pegg-Website.jsx

import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

const GarrettPeggWebsite = () => {
	return (
        <>
            <Helmet titleTemplate="%s | Projects | Garrett Pegg" defaultTitle="Garrett Pegg - Web Developer"><title itemProp="name" lang="en">My Website</title></Helmet>
            <h1>Garrett Pegg</h1>
            <h2>Website Project</h2>
            <hr />
            <h3>General Details</h3>
            <p>The previous versions of my website used content management systems like WordPress, CraftCMS, and ExpressionEngine. I tossed those out because I think a CMS is a little much for how small my website is. I finally understand the question posed by my boss when I was a web development intern - "Do you think this CMS is bloated for what we need it for?" or something of the like. While a CMS like WordPress is really good at what it does, it is not necessary for a website like mine.</p>
            <p>With the current version of my website, I used ReactJS. Why? I studied it for a brief period, thought it was neat, stopped studing it for some reason, and finally forgot everything I learned about it. I decided on ReactJS because it is a popular framework based on JavaScript. This was a good chance to re-learn everything about ReactJS and gain more knowledge about the framework.</p>
            <p><strong>I am not a designer.</strong> I am constantly changing the design of my website because I am just so bad at design. I take inspiration from video games, comics, and the like, but I can never achieve that same feel from the source. You know how some people are born gifted with athletic ability or incredible intelligent? Well, I just feel like I was born with an absence of creativity. Anyway, I hope you enjoy whatever the site currently looks like.</p>

            <hr class="divider" />

            <h3>Skills</h3>
            <ul>
                <li>HTML</li>
                <li>CSS</li>
                <li>JavaScript</li>
                <li>ReactJS</li>
                <li>Web Accessibility</li>
                <li>Website Design</li>
                <li>Responsive Design</li>
                <li>SEO</li>
                <li>Photo Editing</li>
                <li>Website Domain and Hosting</li>
            </ul>
        </>
	)
}

export default GarrettPeggWebsite