// Garrett-Pegg-Website.jsx

import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

const GarrettPeggWebsite = () => {
	return (
        <>
            <Helmet titleTemplate="%s | Work | Garrett Pegg" defaultTitle="Garrett Pegg - Web Developer"><title itemProp="name" lang="en">Webmaster II</title></Helmet>
            <h1>Webmaster II</h1>
            <h2>Maryland Department of Human Services</h2>
            <hr />
            <h3>General Info</h3>
            <p>I would copy &amp; paste the text from the <a href="/Work/Webmaster-Trainee">webmaster trainee page</a>, but that would be lazy, wouldn't it? The jobs are basically the same, except I am now the lead of the web team.</p>
            <p>This job encompasses the skills and duties from <a href="/Work/Webmaster-Trainee">webmaster trainee position</a>, but I've learned even more about those skils and duties required. So, stress management? Oh boy, did I learn even more how to manage stress. Time management &amp; public speaking? You better believe I got better at those.</p>
            <p>What about the technical skils? Yes, those grew/are growing as well. I have learned much more about web accessibility and WordPress themes. I worked on the <a href="/Projects/Mylife-Website">Mylife website project</a> during my time in both positions, but did more with that project during my time in this position.</p>
            <p>Anyways, I pulled the duties and skills below from the job description. Please enjoy.</p>

            <hr class="divider" />

            <h3>Duties</h3>
            <ul>
                <li>Updates and maintains DHS intranet/internet by converting text to Hypertext Markup Language format and maintains record of changes and updates</li>
                <li>Converts documents to hyper-links on the web using web authoring tools as well as checks links to other content in web documents</li>
                <li>Designs, develops, and maintains web pages, including spreadsheets, databases and graphic files and edit existing documents into appropriate formats for the internet and intranet</li>
                <li>Creates and refines graphics for web pages using images and screen captures and converts graphics into correct formats for multiple web sites</li>
                <li>Edits web page code in order to correct errors made during conversions</li>
                <li>Recommends and implements website policies, standards and practices to be followed by users of the internet and intranet</li>
                <li>Assists OTHS and MD Think staff with the development and maintenance of web-based projects, applications and interfaces</li>
                <li>Develops and maintains web-based applications that highlight the Department’s services and programs</li>
                <li>Create and maintain applications to enhance DHS websites and improve customer service</li>
                <li>Recommends upgrades to hardware and software to improve the efficiency and effectiveness of DHS’s web sites</li>
            </ul>

            <h3>Skills</h3>
            <ul>      
                <li>Knowledge of Wordpress CMS & PHP</li>
                <li>Knowledge of Ninja Forms form creation tool</li>
                <li>Proficient with front-end technologies - HTML, CSS, JS</li>
                <li>Familiar with WinSCP & Putty</li>
                <li>Familiar with version control systems such as Git</li>
                <li>Possess a Master's degree in Technology</li>
            </ul>
        </>
	)
}

export default GarrettPeggWebsite