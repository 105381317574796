// Work.jsx

import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

const Work = () => {
	return (
        <>
            <Helmet titleTemplate="%s | Garrett Pegg" defaultTitle="Garrett Pegg - Web Developer"><title itemProp="name" lang="en">Work</title></Helmet>
            <h1>Garrett Pegg</h1>
            <h2>Work</h2>
            <hr />
            <div class="job_list">
                <Job title="Webmaster II" url="/Work/Webmaster-II" employer="Maryland Department of Human Services" duration ="09/2020 - Current" blurb="Literally the same as my previous position, but with a couple of lead responsibilities and a tiny pay raise. Assigning projects, communicating more, etc., etc.." />
                <Job title="Webmaster Trainee" url="/Work/Webmaster-Trainee" employer="Maryland Department of Human Services" duration ="03/2019 - 09/2020" blurb="Designed, developed, and maintained web pages, including spreadsheets, databases, and graphic files and edited existing documents into appropriate formats for the internet and intranet. So-on-and-so-forth." />
                <Job title="Web Production Specialist" url="/Work/Web-Production-Specialist" employer="Money Map Press" duration ="12/2017 - 04/2018" blurb="I learned more about what I didn't want to do than what I wanted to do while I was here. While I did not enjoy my time here, it was still a valuable experience." />
                <Job title="Web Development Intern" url="/Work/Web-Dev-Intern" employer="Chesapeake Bay Program" duration ="01/2017 - 12/2017" blurb="The first step in my career as a web developer. I learned a lot about content management systems, large projects with looming deadlines, and working for people who you care about and who care about you." />
            </div>
        </>
	)
}

 function Job({title, employer, duration, blurb, url}){
    return(
        <>
            <JobURL title={title} employer={employer} duration={duration} blurb={blurb} url={url} />
        </>
    )
}

function JobURL({title, employer, duration, blurb, url}) {
    return <a class="job_list_item" href={url}>
        <JobTitle title={title} employer={employer} />
        <JobDuration duration={duration} />
        <JobBlurb blurb={blurb} />
    </a>
}

function JobTitle({ title, employer }) {
    return <h3>{title}, <span>{employer}</span></h3>
}

function JobDuration({ duration }) {
    return <p class="duration">{duration}</p>
}

function JobBlurb({ blurb }) {
    return <p>{blurb}</p>
}

export default Work