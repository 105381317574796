// Garrett-Pegg-Website.jsx

import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

const GarrettPeggWebsite = () => {
	return (
        <>
            <Helmet titleTemplate="%s | Work | Garrett Pegg" defaultTitle="Garrett Pegg - Web Developer"><title itemProp="name" lang="en">Webmaster Trainee </title></Helmet>
            <h1>Webmaster Trainee</h1>
            <h2>Maryland Department of Human Services</h2>
            <hr />
            <h3>General Info</h3>
            <p>I pulled everything below from the job description. That skill at the bottom - "Possess a Master's degree in Technology" - was not an actual qualification, but the rest were.</p>
            <p>During my time as a webmaster trainee, I spent most of it learning. It was pretty slow since the lead took most of the work, so I studied what we used for work and occcasionally studied my own stuff (JavaScript, mainly).</p>
            <p>I learned a lot about WordPress, plugins, themes/templates, accessibility, and just other general technical stuff. I also learned life skills: communication, scheduling meetings, stress management, time management, public speaking, problem-solving, etc..</p>
            <p>There are a couple of skills I'll list here that aren't below which I used for this job:</p>
            <ul>
                <li>Google Analytics</li>
                <li>The Google Suite in General</li>
                <li>Quality Assurance via Tools like SiteImprove &amp; Monsido</li>
                <li>Google Business Profile</li>
                <li>Analytical Presentaions</li>
                <li>Some Others I Surely Forgot</li>
            </ul>

            <hr class="divider" />

            <h3>Duties</h3>
            <ul>
                <li>Updates the DHS Internet by converting text to Hypertext Markup Language (HTML) format;</li>
                <li>and maintains electronic record of changes and updates and correct mistakes made by automatic code generators or to refine page formatting.</li>
                <li>Interacts with representatives from local departments and central office to address modifications to their web pages.</li>
                <li>Posts information by converting existing documents to hyper-links on the web using web-authoring tools.</li>
                <li>Designs and formats documents (i.e. spreadsheets, databases and/or graphic files) for posting information onto the web.</li>
                <li>Creates applications to enhance the website.</li>
                <li>Develops mobile web based applications to highlight agency services to improve customer services.</li>
                <li>Develops the content of web pages and edit existing documents into the appropriate format for Internet and Intranet presentations.</li>
            </ul>

            <h3>Skills</h3>
            <ul>      
                <li>Knowledge of Wordpress CMS & PHP</li>
                <li>Knowledge of Ninja Forms form creation tool</li>
                <li>Proficient with front-end technologies - HTML, CSS, JS</li>
                <li>Familiar with WinSCP & Putty</li>
                <li>Familiar with version control systems such as Git</li>
                <li>Possess a Master's degree in Technology</li>
            </ul>
        </>
	)
}

export default GarrettPeggWebsite