// Garrett-Pegg-Website.jsx

import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

const GarrettPeggWebsite = () => {
	return (
        <>
            <Helmet titleTemplate="%s | Projects | Garrett Pegg" defaultTitle="Garrett Pegg - Web Developer"><title itemProp="name" lang="en">Bay Backpack Website</title></Helmet>
            <h1>Garrett Pegg</h1>
            <h2>Bay Backpack Website</h2>
            <hr />
            <h3>General Info</h3>
            <p>Bay Backpack is a website created by the Chesapeake Bay Program for Teachers who want to teach their students about the Chesapeake Bay. On this website, users can learn about the Chesapeake Bay, find resource guides for student lessons, project guides for school projects, learn about funding opportunities, and learn about Meaningful Watershed Educational Experiences.</p>

            <p>For this project, I was to create a new section on the website. This section ended up being named School Projects. Using a content management system, I created a the new section, putting together page templates, categories, and custom fields specific to the section.</p>

            <p>Using Bootstrap, I had to match the look and feel of the rest of the website as well as work with the content suppliers to match layout specifications.</p>

            <p>Before, during, and even after the project I had to maintain and update the Website. It was the first website, minus my own, I took ownership of. If there was an error, I wanted to fix it. If it was slowing down, I wanted to speed it up. I had a great time with the website and cannot thank UMCES and the Chesapeake Bay Program enough.</p>

            <a href="https://www.baybackpack.com/" class="demo_link" target="_blank" rel="nofollow">Bay Backpack Website</a>

            <hr class="divider" />

            <h3>Skills</h3>
            <ul>
                <li>HTML</li>
                <li>CSS</li>
                <li>JavaScript</li>
                <li>ExpressionEngine (CMS)</li>
                <li>Web Accessibility</li>
                <li>Website Design</li>
                <li>Responsive Design</li>
                <li>Client Communication</li>
            </ul>
        </>
	)
}

export default GarrettPeggWebsite