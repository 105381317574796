// Work.jsx

import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

const Projects = () => {
	return (
        <>
            <Helmet titleTemplate="%s | Garrett Pegg" defaultTitle="Garrett Pegg - Web Developer"><title itemProp="name" lang="en">Projects</title></Helmet>
            <h1>Garrett Pegg</h1>
            <h2>Work</h2>
            <hr />
            <div class="job_list">
                <Project title="Garrett Pegg Website" url="/Projects/Garrett-Pegg-Website" blurb="My website. The thing you are currently looking at. I moved away from WordPress to practice ReactJS after a long while. I borrowed some design elements from a game I played. Not too bad looking, but I had hoped it would turn out better." />
                <Project title="Mylife Website" url="/Projects/Mylife-Website" blurb="Designed and built by yours truly. I was a part of this project from start to finish. From concept, to launch I attended meetings, interviewed foster children, and pulled teeth to get this website up and running." />
                <Project title="Bay BackPack Website" url="/Projects/Bay-Backpack-Website"  blurb="A website I worked on during my time at the Chesapeake Bay Program which used the ExpressionEngine CMS. I added two new sections to the website, which was a heavy lift for someone just starting their career." />
                <Project title="jQuery Tic-Tac-Toe" url="/Projects/Jquery-Tic-Tac-Toe" blurb="A simple jQuery project I put together where you can play tic-tac-toe. I put this together after watching a jQuery Basics course on Treehouse." />
            </div>
        </>
	)
}

 function Project({title, blurb, url}){
    return(
        <>
            <ProjectURL title={title} blurb={blurb} url={url} />
        </>
    )
}

function ProjectURL({ url, title, blurb }) {
    return <a class="job_list_item" href={url}>
        <ProjectTitle title={title} />
        <ProjectBlurb blurb={blurb} />
    </a>
}
function ProjectTitle({ title }) {
    return <h3>{title}</h3>
}

function ProjectBlurb({ blurb }) {
    return <p>{blurb}</p>
}

export default Projects