// Garrett-Pegg-Website.jsx

import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

const GarrettPeggWebsite = () => {
	return (
        <>
            <Helmet titleTemplate="%s | Projects | Garrett Pegg" defaultTitle="Garrett Pegg - Web Developer"><title itemProp="name" lang="en">jQuery Tic-Tac-Toe</title></Helmet>
            <h1>Garrett Pegg</h1>
            <h2>jQuery Tic-Tac-Toe</h2>
            <hr />
            <h3>General Info</h3>
            <p>A simple jQuery project I put together where you can play tic-tac-toe. I put this together after watching a jQuery Basics course on Treehouse. It requires some cleaning up, but it still works as I had desired.</p>

            <p>I created this tic-tac-toe game after watching jQuery basics on Treehouse.com and using mainly jQuery. First, I define all variables using ES6 standards. Second I set up the main function used for the game, which needs simplification. This function tracks the tiles checked and: 1. does not allow a tile to be checked twice. 2. if all tiles are check and there is no winner, end the game. 3. If 3 tiles in a row/column/diagonal line are checked, check if the winner is ‘X’ or ‘O’ and inform the players of the winner. There is also the rules dropdown, but that is super simple.</p>

            <p>The code needs to be simplified, but it works as it should and I have other projects to work on, so that is on the backburner.</p>

            <a href="https://gpegg.github.io/jQuery-Tic-Tac-Toe/" class="demo_link" target="_blank" rel="nofollow">Demo Link</a>

            <hr class="divider" />

            <h3>Skills</h3>
            <ul>
                <li>HTML</li>
                <li>CSS</li>
                <li>JavaScript</li>
                <li>ReactJS</li>
                <li>Web Accessibility</li>
                <li>Website Design</li>
                <li>Responsive Design</li>
            </ul>
        </>
	)
}

export default GarrettPeggWebsite